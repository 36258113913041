import React from "react";
import { graphql } from "gatsby";

import Layout from "../layouts";
import NoImage from "../components/NoImage";
import styled from "styled-components";

const Cards = styled.div`
  display: grid;

  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-gap: 20px;

  @media only screen and (min-width: 500px) {
    grid-template-columns: 1fr;
  }

  @media only screen and (min-width: 860px) {
    grid-template-columns: 1fr 1fr;
  }

  @media only screen and (min-width: 1260px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

const Card = styled.div`
  text-decoration: none;
  color: #444;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;

  p {
    margin-top: 0 !important;
  }
`;

const Headshot = styled.div`
  img {
    border-radius: 50%;
    height: 250px;
    width: 250px;
    object-fit: cover;
    margin-bottom: 20px;

    &:hover {
      border: 4px solid #f4d1cd;
    }
  }

  a {
    width: 100%;
    margin: 0;
    padding: 0;
  }

  a:before {
    background: none;
  }
`;

export default ({ data }) => (
  <Layout>
    <h1>Medarbetare</h1>
    <Cards>
      {data.allAirtable.edges.map(edge => (
        <Card key={edge.node.id}>
          <Headshot>
            <a href={edge.node.fields.slug}>
              {edge.node.data.Headshot ? (
                <img
                  src={`${edge.node.data.Headshot[0].thumbnails.large.url}`}
                  alt={`Headshot of an employee at Evolve called ${
                    edge.node.data.Name
                  }`}
                />
              ) : (
                <NoImage />
              )}
            </a>
          </Headshot>
          <p>
            <strong>{edge.node.data.Name}</strong>
            <br />
            <a href={`tel:${edge.node.data.Phone}`}>
              {edge.node.data.Phone ? edge.node.data.Phone : "N/A"}
            </a>
            <br />
            <a href={`mailto:${edge.node.data.Email}`}>
              {edge.node.data.Email}
            </a>
            <br />
            <br />
            {edge.node.data.Parental_leave ? `Föräldraledig från ${edge.node.data.Parental_leave}` : ""}
          </p>
        </Card>
      ))}
    </Cards>
  </Layout>
);

export const query = graphql`
  query {
    allAirtable(
      filter: {
        table: { eq: "Employees" }
        data: { Status: { eq: "Employee" } }
      }
      sort: { fields: [data___Name], order: ASC }
    ) {
      edges {
        node {
          id
          data {
            Name
            Email
            Phone
            Parental_leave
            Headshot {
              thumbnails {
                large {
                  url
                  height
                  width
                }
              }
            }
          }
          fields {
            slug
          }
        }
      }
    }
  }
`;
